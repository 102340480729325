export default {
  unknown_error: 'Возникла непредвиденная ошибка сервера. Пожалуйста, воспользуйтесь формой обратной связи.',
  captcha_error: 'Подтвердите, что Вы не робот!',
  continue: 'Продолжить',
  submit: 'Отправить',
  learn_more: 'Подробно',
  clear: 'Очистить',
  search: 'Поиск',
  search_by_number: 'Поиск по списку',
  product_code: 'Артикул',
  product_name: 'Наименование',
  no_vat: 'без НДС',
  price: 'Цена',
  price_rub: 'Цена, руб.',
  sum_rub: 'Сумма, руб.',
  total_vat_rub: 'Итого с НДС, руб.',
  total_rub: 'Итого, руб.',
  vat_rub: 'НДС (20%), руб.',
  quantity: 'Количество',
  profile: 'Профиль',
  login: 'Логин',
  first_name: 'Имя',
  last_name: 'Фамилия',
  company_name: 'Название компании',
  company_ownership: 'Форма собственности',
  country_phone: 'Страна и номер телефона',
  inn: 'ИНН',
  kpp: 'КПП',
  set_password: 'Задайте пароль',
  confirm_password: 'Повторите пароль',
  news: 'Новости',
  all_news: 'Все новости',
  orders: 'Заказы',
  favourites: 'Избранное',
  to_homepage: 'На главную',
  cart: 'Корзина',
  go_to_cart: 'Перейти в корзину',
  express: 'Экспресс',
  prev_btn: 'Назад',
  next_btn: 'Далее',
  price_requests: 'Мои запросы',
  search_history: 'История загрузок списком',
  print: 'Печать',
  save: 'Сохранить',
  specialization: 'Специализация',
  all_vehicles: 'Все типы транспортных средств',
  where_to_buy: 'Где купить?',
  list_of_distributors: 'Список дистрибьюторов',
  download_order_file: 'Скачать файл заказа',
  select_distributor: 'Выбрать дистрибьютора',
  maker_engine: 'Производитель',
  model_engine: 'Модель двигателя',
  show: 'Показать',
  applicability: 'Применяемость',
  mark: 'Марка',
  model: 'Модель',
  year_manufacture: 'Год выпуска',
  engine: 'Двигатель',
  makers: 'Производители',
  apply_filters: 'Применить фильтры',
  all_products: 'Показать все',

  quantity_pcs: 'Количество мест, шт.',
  add_comment: 'Добавить комментарий',
  // order_checkout_manager_contact_text: 'Отправьте заказ и наш менеджер свяжется с Вами в ближайшее время. Сумма указана без стоимости доставки',
  print_document: 'Печать документов',
  delete_cart: 'Очистить корзину',
  empty_cart: 'Ваша корзина пуста.',
  delete_cart_confirm_1: 'Вы уверены, что хотите очистить корзину?',
  delete_cart_confirm_2: 'Подтвердите действие!',
  checkout_confirm_1: 'Подтвердите отправку заказа!',
  checkout_confirm_2: 'После отправки заказ изменить нельзя!',
  checkout_success_header: 'Ваш заказ отправлен!',
  checkout_success_text_1: 'Менеджер свяжется с Вами в ближайшее время.',
  checkout_success_text_2: 'На адрес электронной почты, указанный при регистрации на сайте, отправлено письмо с файлом заказа. Если письмо не пришло, проверьте папку Спам.',
  added_pcs: 'добавлено',
  deleted_pcs: 'удалено',
  pcs: 'шт.',
  products_moved_warning: 'Внимание! Из-за изменения количества товаров на складе, были изменены следующие позиции:',
  print_cart: 'Печать корзины',

  page_title: 'Заказы - Личный кабинет',
  search_by_order_number_product: 'Название товара, артикул, номер заказа',
  dates: 'Даты',
  period: 'Период',
  stock_type_available: 'В наличии',
  stock_type_transit: 'В пути',
  stock_type_preorder: 'Под заказ',
  stock_type_available_mobile: 'В нал.',
  stock_type_transit_mobile: 'В пути',
  stock_type_preorder_mobile: 'Под заказ',
  period_all: 'За все время',
  period_today: 'Сегодня',
  period_yesterday: 'Вчера',
  period_week: 'Неделя',
  // period_week: 'Текущая неделя',
  period_lastweek: 'Прошлая неделя',
  period_month: 'Месяц',
  // period_month: 'Текущий месяц',
  period_lastmonth: 'Прошлый месяц',
  period_year: 'Год',
  // period_year: 'Текущий год',
  period_lastyear: 'Прошлый год',

}
