export default {
  unknown_error: 'Unknown server error occurred. Please contact us via feedback form.',
  captcha_error: 'Please confirm that you\'re a human!',
  continue: 'Continue',
  submit: 'Submit',
  learn_more: 'Learn more',
  clear: 'Clear',
  search: 'Search',
  search_by_number: 'Search by codes',
  product_code: 'Code',
  product_name: 'Name',
  no_vat: 'w/o VAT',
  price: 'Price',
  price_rub: 'Price, RUB',
  sum_rub: 'Total price, RUB',
  total_vat_rub: 'Total price with VAT, RUB',
  total_rub: 'Total price, RUB',
  vat_rub: 'VAT (20%), RUB',
  quantity: 'Quantity',
  profile: 'Profile',
  login: 'Login',
  first_name: 'First name',
  last_name: 'Last name',
  company_name: 'Company name',
  company_ownership: 'Type of ownership',
  country_phone: 'Country and phone number',
  inn: 'Tax number',
  kpp: 'Additional tax number',
  set_password: 'Password',
  confirm_password: 'Confirm password',
  news: 'News',
  all_news: 'All news',
  orders: 'Orders',
  favourites: 'Favourite products',
  to_homepage: 'To homepage',
  cart: 'Cart',
  go_to_cart: 'Go to cart',
  express: 'Express',
  prev_btn: 'Previous',
  next_btn: 'Next',
  price_requests: 'My requests',
  search_history: 'Search history',
  print: 'Print',
  save: 'Save',
  specialization: 'Specialization',
  all_vehicles: 'All types of vehicles',
  where_to_buy: 'Where to buy?',
  list_of_distributors: 'List of distributors',
  download_order_file: 'Download order file',
  select_distributor: 'Select distributor',
  maker_engine: 'Manufacturer',
  model_engine: 'Engine model',
  show: 'Show',
  applicability: 'Applicability',
  mark: 'Brand',
  model: 'Model',
  year_manufacture: 'Year of manufacture',
  engine: 'Engine',
  makers: 'Manufacturers',
  apply_filters: 'Apply filters',
  all_products: 'View all',

  quantity_pcs: 'Quantity, pcs.',
  add_comment: 'Add comment',
  // order_checkout_manager_contact_text: 'Checkout your order and our manager will contact you shortly. Total price is specified without delivery',
  print_document: 'Print document',
  delete_cart: 'Clear cart',
  empty_cart: 'Your cart is empty.',
  delete_cart_confirm_1: 'Are you sure you want to clear cart?',
  delete_cart_confirm_2: 'Confirm action!',
  checkout_confirm_1: 'Confirm order checkout!',
  checkout_confirm_2: 'Order can not be changed after checkout!',
  checkout_success_header: 'Your order is submitted!',
  checkout_success_text_1: 'Our manager will contact you shortly.',
  checkout_success_text_2: 'We sent order document to an Email you specified during the registration. If you did not receive it please check "Spam" folder.',
  added_pcs: 'added',
  deleted_pcs: 'removed',
  pcs: 'pcs.',
  products_moved_warning: 'Warning! Due to products stocks movement, these positions have been changed:',
  print_cart: 'Print cart',

  page_title: 'Orders - Personal account',
  search_by_order_number_product: 'Product name, article number, order number',
  dates: 'Date',
  period: 'Period',
  stock_type_available: 'Available',
  stock_type_transit: 'In transit',
  stock_type_preorder: 'Preorder',
  stock_type_available_mobile: 'Available',
  stock_type_transit_mobile: 'Transit',
  stock_type_preorder_mobile: 'Preorder',
  period_all: 'All time',
  period_today: 'Today',
  period_yesterday: 'Yesterday',
  period_week: 'Week',
  // period_week: 'This week',
  period_lastweek: 'Last week',
  period_month: 'Month',
  // period_month: 'This month',
  period_lastmonth: 'Last month',
  period_year: 'Year',
  // period_year: 'This year',
  period_lastyear: 'Last year',
}